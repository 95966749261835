/* eslint-env browser */

// CSS and SASS files
import './index.scss';

import Tobii from '@midzer/tobii';
import Darkmode from 'darkmode-js';
import AnchorJS from 'anchor-js';

Tobii();
new Darkmode({
  right: 'unset',
  bottom: 'unset',
  label: '',
}).showWidget();

const anchors = new AnchorJS();
anchors.options.placement = 'left';
anchors.options.class = 'anchor-link';
anchors.options.icon = '#';
anchors.add();
